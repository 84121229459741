.btn {
  border: 1px solid #008a00;
  border-radius: 0px;
}

.primary {
  background-color: #008a00;
  color: #fff;
  border: none;
}

.default {
  background-color: #fff;
  color: #038203;
}

/* .btn:focus {
  outline: 4px solid #000 !important;
  outline-offset: 5px !important;
} */
