#titleGrid,
#addToCardGrid,
.buyBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buy-box-qty-price-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding-top: 0px;
  flex-wrap: wrap;
}

.add-to-cart-grand-total-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 14px;
  gap: 10px;
  border-top: 2px solid #78a9a9;
}
.add-to-cart-grand-total-wrapper > div {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.sub-total-wrapper {
  justify-content: flex-end !important;
  color: rgb(204, 0, 0);
}
.inStock-wrapper {
  font-size: 20px;
}

.inStock-wrapper label {
  font-weight: 600;
}

.buy-box-qty-wrapper {
  width: 100%;
}

.buy-box-price-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.unit-price-wrapper {
  font-size: 18px !important;
}

.unit-price-wrapper.no-package-price {
  font-size: 38px !important;
}

.quantity-selector-wrapper.no-table {
  margin-top: 30px;
}

.first-package-price-wrapper,
.quantity-selector-wrapper {
  width: 100%;
}

.quantity-selector-wrapper label {
  width: 30%;
  padding: 10px 0;
  font-size: 14px;
  line-height: initial;
}
.quantity-selector-wrapper select {
  width: 70%;
}

.first-package-unit {
  font-size: 22px;
}
.first-package-price {
  font-size: 42px;
  font-weight: bold;
}

.addToCartBtn.wiggle {
  animation: wiggle 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  perspective: 1000px;
}

.add-to-cart-btn-container > button {
  width: 100%;
  font-size: 1.2rem;
  padding: 10px;
  font-weight: normal;
}

.pricing-table-wrapper {
  padding: 1rem 0;
  width: 100%;
}
.pricing-table-header {
  display: flex;
  justify-content: space-between;
}

.pricing-table-header p {
  font-size: 1.08em;
  font-weight: 600;
}

.pricing-table table {
  margin-top: 10px;
  border: 1px solid #333;
}

.pricing-table {
  overflow: hidden;
  overflow-x: auto;
}
.pricing-table th {
  padding: 2px 5px;
  background-color: #ccc;
}

.pricing-table tr:nth-child(odd) {
  background-color: #eee;
}

.pricing-table td {
  padding: 5px;
}

.pricing-table td,
.pricing-table th {
  border-right: 1px solid #333;
}

@keyframes wiggle {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media all and (max-width: 768px) {
  .inStock-wrapper {
    font-size: 16px;
  }
}
