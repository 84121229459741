/* html,
body,
#root,
.root-child {
  height: 100%;
} */

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

#root[aria-hidden="true"] .positionAb > header {
  padding-right: 0px !important;
}

.root-child {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

a {
  line-height: initial;
}

footer#footer {
  margin-top: auto;
}
body {
  font-family: "Helvetica LT W05 Roman", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
}

*:not(div:not(.focusAble)):focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}

/* IE11 only start*/
*::-ms-backdrop,
*:focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}

/* IE11 only end*/

input#search-input.search-form-input {
  padding: 0 5px;
  border-bottom: 1px solid #444 !important;
  text-align: right;
}

#searchForm {
  position: relative;
}

.carousel-custom-right-button,
.carousel-custom-left-button {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  justify-content: flex-end;
  right: calc(4% - 1px) !important;
}

.carousel-custom-left-button {
  left: calc(4% - 1px) !important;
}

.carousel-custom-right-button:before,
.carousel-custom-left-button:before {
  content: "\e825";
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.carousel-custom-left-button:before {
  content: "\e824";
}

.review-process-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.review-process-loading-wrapper {
  padding: 30px;
  height: 200px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.reviewProcessLoading {
  width: 100%;
  height: 10px;
}
.reviewProcessLoading > div {
  height: 100%;
  width: 100%;
}

.preview-control {
  position: relative;
  display: none;
  background-color: #e6e7e9;
  color: #038203;
  font-weight: 600;
  font-size: 1rem;
  padding: 5px 2.5%;

  border-top: 1px solid #038203;
  border-bottom: 1px solid #038203;
}

#search-mobile-icon-btn {
  color: #000;
  cursor: pointer;
}

ul.staticMenu li {
  text-align: left;
}

ul.staticMenu li a {
  display: flex;
  align-items: center;
}

.mobile-nav-child-cats {
  display: none;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;
}
.mobile-menu-item.active > .mobile-nav-child-cats {
  display: flex;
}

.mobile-nav-child-cats a {
  color: #fff !important;
  font-size: 1em !important;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 25px;
}

input[type="checkbox"] {
  cursor: pointer;
}

.menu-btn-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 10px;
}

.menu-btn-wrapper i {
  cursor: pointer;
}

.logo-container {
  height: 100%;
}
a.brand-logo.logo-i {
  display: flex;
  align-items: center;
}
.brand-logo,
.brand-logo img {
  margin-right: 15px;
  height: 70px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.brand-logo img:nth-child(2) {
  height: 60px !important;
  margin-left: 55px;
}

.header-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.home-item {
  box-shadow: 0 0 1px 2px #108b43;
}

.item-card-figure {
  background-color: #d2e9d8;
  height: 100%;
  width: 100%;
}

.product-title {
  font-weight: 600;
  font-size: 14px;
}

.item-code-wrapper,
.item-code-wrapper > div {
  color: #0b602e !important;
}

.product-price--main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-price--main > div {
  float: unset;
  display: flex;
  width: auto;
}

.product-price--main--left {
  font-style: italic;
}

.configure {
  background-color: #008a00;
  border: none;
  border-radius: 0;
  color: #fff;
}

.configure:hover {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);
}

.dropdown5 {
  position: relative;
}
.dropbtn5 {
  display: flex;
  align-items: center;
}

.dropdown-content5 {
  background-color: #ffffff;
  color: #1c1c1c;
  margin: 0;
  top: 46px;
  left: 0px;
  z-index: 2;
}

.return-url-link {
  margin-left: auto;
}

.cate li {
  padding-left: 0;
  display: flex;
}

.cate li a,
.cate li span {
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  color: #fff;
  font-weight: 600;
}
.cate li a:hover,
.cate li a:active,
.cate li a:focus {
  color: #fff !important;
}

.dropdown-content5 li {
  background: #fff;
  border: 1px solid #8c8c8c;
  color: #1c1c1c !important;
}

.dropdown-content5 li {
  background: #fff;
  border: 1px solid #8c8c8c;
  color: #1c1c1c !important;
}

.dropdown-content5 li a:hover {
  color: #fff !important;
}
.dropdown-content5 a {
  color: #1c1c1c !important;
}
.carousel-container {
  padding: 0;
  padding-bottom: 10px;
}

.parent-cat-wrapper {
  width: 100%;
}

.main-category-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
}

.main-category-wrapper {
  position: relative;
  height: 100%;
  border-radius: 50%;
  display: flex;
}

.main-category-wrapper figure {
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  background-size: contain;
  width: 70%;
  height: 70%;
  margin: 0 auto;
  position: initial;
}
.featured-item-con {
  padding-top: 30px;
}

.parent-cat-wrapper h1,
.featured-item-con h2,
.category-title {
  margin-top: 20px;
  color: #1a5336;
  line-height: inherit;
  font-size: 2em;
}

.category-title {
  margin-top: 0;
  font-size: 2em;
}

.main-category-wrapper p {
  bottom: unset;
  margin-top: 15px;
  color: #333;
  font-size: 16px;
  top: 100%;
  left: 0;
  letter-spacing: initial;
  line-height: initial;
}

.divider-gray-gradient {
  margin-bottom: 20px;

  height: 5px;
  width: 100%;
  background: rgb(186, 186, 186);
  background: linear-gradient(
    90deg,
    rgba(186, 186, 186, 1) 0%,
    rgba(210, 210, 210, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.main-category-item {
  border-radius: 50%;
  height: 95%;
  width: 95%;
  background-color: #d2e9d8;
  border: 2px solid #108b43;
  position: absolute;
  top: 0;
  margin-left: 2.5%;
}

.main-category-item a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.NaviMenu {
  background-color: #008a00 !important;
  margin: 0;
}
.facetsContent:not(.mobile) {
  margin-top: 10px;
  box-shadow: 0 0 1px 2px #999;
  background-color: #f2f3f4;
}

.filt {
  padding-bottom: 0;
  border-bottom: 2px solid #999;
}

.facetTitleWrapper {
  margin-top: 5px;
  background-color: #fff;
  padding: 5px;
}

i.facet-toggle-icon {
  font-weight: 600;
  font-size: 24px;
}
.category-title-sort-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.reviewInProgressWrapper {
  background-color: #d8e4d7;
  color: #1c1c1c;
  box-shadow: 0 0 0 1px #ccc;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px 10px;
  font-size: 0.8rem;
  transform: translateY(-100%) translateY(1px);
}

.pagination-container {
  padding: 0;
}

.item-card-container {
  margin: 0;
}

.item-card-item {
  padding: 10px;
}

.product-page-title-code-wrapper {
  display: flex;
  justify-content: space-between;
}

.product-details-specs-wrapper {
  min-width: 55%;
  height: 100%;
  width: unset;
  margin: 0;
}

#product-specs {
  font-size: 1rem;
}

#product-specs tr {
  width: 100%;
}

#product-specs tr td {
  border: 2px solid #fff;
  padding: 8px;
}

#product-specs tr td:nth-child(2n-1) {
  background-color: #e6e7e8;
}

#product-specs tr td:nth-child(2n) {
  background-color: #f1f1f2;
}

.products-specs-content {
  padding: 1rem;
}

.product-specs-wrapper {
  width: 100%;
}
.product-specs-wrapper h2 {
  display: flex;
  align-items: center;
}

.product-specs-wrapper h2 > i,
.product-page-specs-btn i {
  padding-right: 5px;
  color: #1074bc;
}

#product-specs ul {
  list-style: disc;
  line-height: 2;
}

#product-specs ul li::marker {
  font-size: 18px;
}

#product-specs ul li {
  margin-left: 20px;
}

.product-page-specs-btn:focus,
#product-specs:focus {
  outline: 2px dashed #000 !important;
  outline-offset: 3px !important;
}

.product-page-specs-btn {
  display: flex;
  align-items: center;
}
.product-page-specs-btn span {
  text-decoration: underline;
  color: #038203;
  font-size: 14px;
  cursor: pointer;
}
.specs-info-container {
  display: flex;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
}

.product-details-specs-container {
  width: calc(55% - 20px);
}

.product-details-specs-container.full-width {
  width: 100%;
}

.c-full {
  width: 45%;
  flex-direction: column;
}
.c-left {
  margin: 0;
  margin-bottom: 10px;
}
.c-right,
.c-left {
  width: 100%;
  height: 50%;
}

.item-main,
.sub-nav-bread {
  width: 95%;
}

#leftGrid {
  width: 55%;
  min-width: unset;
  margin: 0;
  width: 55%;
  width: calc(55% - 20px);
}

#bd {
  margin-top: 140px !important;
  margin-bottom: 70px !important;
}

.header-container[data-hidden="true"] ~ #bd {
  /*   padding-top: 40px !important; */
}

.signin-message-wrapper {
  padding: 10px;
  color: rgb(204, 0, 0);
  text-align: center;
}

.imageCarousel-canvas {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.last_breadcrumb,
.breadcrumb-link {
  color: #09aede;
  font-weight: 400 !important;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 14px;
}

.breadcrumb > li > a > i {
  margin: 0;
}

.first-package-price-wrapper {
  border-bottom: 2px solid #78a9a9;
  padding-bottom: 5px;
}

.first-package-price {
  font-size: 20px;
  color: rgb(204, 0, 0);
}
.first-package-unit,
.first-package-price-starting-from {
  font-size: 16px;
}
.add-to-cart-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-to-cart-previous-btn {
  margin-right: 30px;
  font-weight: 600 !important;
}

.add-to-cart-btn-text {
}

.add-to-cart-grand-total-wrapper p {
  font-weight: 1.08em;
  font-weight: 600;
}

.add-to-cart-btn-text span {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
}

.main-category-modal-content {
  padding: 20px;
}

.main-category-modal-content p {
  font-size: 1.5rem;
  line-height: initial;
}

.footer-expand-button {
  position: absolute;
  background: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36px;
  padding: 0;
  padding-top: 3px;
  font-size: 0.7rem;
}

@media all and (max-width: 768px) {
  a.brand-logo.logo-i {
    height: 100%;
    padding: 0 !important;
  }
  .brand-logo {
    display: flex;
    align-items: center;
  }
  .footer-links p:first-of-type {
    padding-top: 40px;
    margin: 0;
  }
  #finePrint img {
    height: 30px !important;
    margin: auto;
  }
  .main-category-promotion {
    font-size: 1.2rem;
  }
  a.brand-logo {
    margin: 0;
  }
  nav .brand-logo img {
    height: 18px !important;
    margin: 0;
    padding: 0;
  }
  #finePrint small {
    margin: 0;
    font-size: 10px;
    height: 40px;
    line-height: 10px !important;
    display: flex;
    align-items: center;
    padding-left: 38px;
  }
  .item-main {
    width: 100%;
    margin: 0;
  }
  nav.nav-extended,
  nav.nav-extended .nav-wrapper,
  nav.nav-extended .header-container {
    height: 55px !important;
  }

  .preview-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-content5 {
    top: 34px;
  }
  .cate li a,
  .cate li span {
    font-size: 10px;
    text-transform: uppercase;
  }

  .cate li {
    padding: 2px;
  }
  .dropbtn5 i {
    margin-left: -5px;
  }
  #bd {
    margin-top: 89px !important;
  }

  .header-container[data-hidden="true"] ~ #bd {
    /*  padding-top: 80px !important; */
  }

  #leftGrid {
    width: 100%;
  }

  .specs-info-container {
    flex-direction: column;
  }

  .product-details-specs-container,
  .c-full {
    width: 100%;
  }

  .c-full {
    margin-top: 30px;
  }
  .c-left,
  .c-right {
    height: 100px;
  }

  .itemTitleMobile {
    margin-bottom: 10px;
  }

  .itemTitleMobile,
  .product-page-item-code {
    padding: 0 2.5% !important;
  }

  .grand-total-container {
    background-color: #fff;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 40px;
    z-index: 1;
  }

  .add-to-cart-grand-total-wrapper {
    border-top: 2px solid #666;
    padding: 5px 2.5%;
  }

  .add-to-cart-grand-total-wrapper p {
    font-size: 0.9rem;
  }

  .add-to-cart-btn-container {
    margin-bottom: 5px;
    padding: 0 2.5%;
  }

  .add-to-cart-btn-container button {
    padding: 5px;
    font-size: 1rem;
  }
}
